<template>
    <div class="help-center">
        <div class="banner-wrap" :style="`background: url(${bg}) center no-repeat; background-size: 100% 100%;`">
            <div class="main-content-wrap conten-wrap">
                <div class="title">{{ $t('rap.helpCenter.banner.title') }}</div>
                <div class="search">
                    <i class="el-icon-search"></i>
                    <span>{{ $t('rap.common.button.search') }}</span>
                </div>
            </div>
        </div>
        <div class="main-content-wrap conten-wrap">
            <div class="list-item" v-for="(item, index) in questions" :key="index">
                <div class="title-wrap" @click="handleExpand(item)">
                    <div class="title">{{ item.title }}</div>
                    <div class="arrow-icon">
                        <transition name="el-fade-in">
                            <img v-if="!item.show" src="@/assets/arrow-img-down.png" alt="">
                            <img v-else src="@/assets/arrow-img-up.png" alt="">
                        </transition>
                    </div>
                </div>
                <el-collapse-transition>
                    <div class="text" v-if="item.show">
                        {{ item.content }}
                    </div>
                </el-collapse-transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            bg: require('@/assets/help-center-bg.png'),
            activeName: [],
            questions: [
                {
                    name: 1,
                    title: this.$t('rap.helpCenter.content.question-01'),
                    content: this.$t('rap.helpCenter.content.answer-01')
                },
                {
                    name: 2,
                    title: this.$t('rap.helpCenter.content.question-02'),
                    content: this.$t('rap.helpCenter.content.answer-02')
                },
                {
                    name: 3,
                    title: this.$t('rap.helpCenter.content.question-03'),
                    content: this.$t('rap.helpCenter.content.answer-03')
                },
                {
                    name: 4,
                    title: this.$t('rap.helpCenter.content.question-04'),
                    content: this.$t('rap.helpCenter.content.answer-04')
                },
                {
                    name: 5,
                    title: this.$t('rap.helpCenter.content.question-05'),
                    content: this.$t('rap.helpCenter.content.answer-05')
                },
                {
                    name: 6,
                    title: this.$t('rap.helpCenter.content.question-06'),
                    content: this.$t('rap.helpCenter.content.answer-06')
                },
                {
                    name: 7,
                    title: this.$t('rap.helpCenter.content.question-07'),
                    content: this.$t('rap.helpCenter.content.answer-07')
                }
            ]
        }
    },
    methods: {
        handleChange () {},
        handleExpand (item) {
            this.$set(item, 'show', !item.show)
        }
    }
}
</script>

<style lang="scss" scoped>
    .help-center{
        background: white;
        .banner-wrap{
            height: 340px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .title{
                color: white;
                opacity: 0.96;
                font-size: 34px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                margin-bottom: 20px;
            }
            .search{
                margin: 0 auto;
                width: 1000px;
                background: white;
                padding: 18px 16px;
                font-size: 20px;
                box-shadow: 0px 0px 19px 2px #ff7734;
                border-radius: 2px;
                color: #999999;
                overflow: hidden;
                span{
                    margin: 10px;
                    height: 22px;
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #9ca2a9;
                }
            }
        }
        .conten-wrap{
            margin: 0 auto;
            padding: 50px 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .list-item{
            background: #fafafa;
            border-radius: 2px;
            padding: 17px 40px;
            margin-bottom: 16px;
            .title-wrap{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .title{
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 8px;
                }
                .arrow-icon{
                    padding: 10px;
                    img {
                        display: inline-block;
                        width: 14px;
                        height: 8px;
                    }
                }
            }
            .text{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #343647;
                line-height: 20px;
            }
        }
    }
</style>
